import React,{Fragment} from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Map from '../components/map'
import Blog3 from '../components/Blog3/index'
import PageAbout from "../components/About/page";
import Services from "../components/Services";
import PricingTable from "../components/PricingTable";
import Testimonial from "../components/Testimonials/home-two";
import BrandLogo from "../components/BrandLogo";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import Content from '../components/UI/Content'
import Thumbnail from '../components/UI/Thumbnail'
import SectionTitle from '../components/UI/SectionTitle'
import ServiceThumb from '../assets/img/mapa-buenoo.jpg'
import ImgHistoria from '../assets/img/somos-historia.jpg'
import ImgMision from '../assets/img/somos-mision.jpg'
import ImgVision from '../assets/img/somos-vision.jpg'

const Somos = () => {
    return (
        <Fragment>
            <Header/>
            <PageHeader
                bgImg={require('../assets/img/somos-banner.jpg')}
                title="SOMOS"
                style={{color:'rgba(76, 79, 81, 0.92)'}}
                content=""
            />
            <div className="page-about-wrapper sm-top">
            <div className="container">
            <div className="row">
            <div className="col-12 text-center">
                <SectionTitle  title="" heading="Una asociacion civil que trabajaja desde hace 23 años en Yucatán"/>
            </div>
            </div>
                <div className="row align-items-lg-center" style={{display:'flex', flexDirection:'row', flexWrap:'wrap-reverse', alignItems:'center'}} >
                    <div className="col-md-6 col-lg-7 order-1">
                        <Content classes="about-content ml-0" >
                         
                            <h2>Historia</h2>
                            <p>La red de 55 Bancos de Alimentos de México BAMX, es una de las organizaciones civiles más importantes del país. Es la única red de bancos de alimentos en México y la segunda más grande del mundo. En Mérida, Banco de Alimentos se crea a instancias de un grupo de jóvenes en 1996 para contribuir a paliar el rezago alimentario en las comunidades más desprotegidas de Yucatán. Hoy día, el BAMX de Mérida recolecta, almacena y distribuye mensualmente cientos de toneladas de despensas alimentarias en beneficio de municipios, instituciones de asistencia social y miles de familias beneficiarias.</p>
                        </Content>
                    </div>

                    <div className="col-md-6 col-lg-5 order-0 order-md-1">
                        <Blog3/>
                    </div>
                </div>
            </div>
        </div>
        <div style={{backgroundColor:'#ffc966', padding:'1% 0 8% 0 '}}>
        <div className="page-about-wrapper sm-top">
            <div className="container">
                <div className="row align-items-lg-center" style={{display:'flex', flexDirection:'row', flexWrap:'wrap-reverse', alignItems:'center'}} >

                    <div className="col-md-6 col-lg-5 order-0 order-md-1">
                        <Thumbnail classes="about-thumb" imgSrc={ImgMision}/>
                    </div>
                    <div className="col-md-6 col-lg-7 order-1">
                        <Content classes="about-content ml-0">
                           
                            <h2>Misión</h2>
                            <p>Somos una Asociación Civil sin fines de lucro que promueve la participación social para aprovechar el producto alimentario no comercializable, entregando dotaciones nutrimentales como apoyo a personas en situación de pobreza.</p>
                        </Content>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="page-about-wrapper sm-top">
            <div className="container">
                <div className="row align-items-lg-center" style={{display:'flex', flexDirection:'row', flexWrap:'wrap-reverse', alignItems:'center'}}>
                    <div className="col-md-6 col-lg-7 order-1">
                        <Content classes="about-content ml-0" style={{textAlign:'center !important'}}>
                            <h2>Visión</h2>
                            <p>Ser reconocido por la sociedad yucateca como el medio más eficiente, confiable y profesional para otorgar alimentos a los más necesitados y brindarles educación alimenticia.</p>
                        </Content>
                    </div>

                    <div className="col-md-6 col-lg-5 order-0 order-md-1">
                        <Thumbnail classes="about-thumb" imgSrc={ImgVision}/>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div className="page-about-wrapper sm-top" style={{marginBottom:'90px'}}>
            <div className="container">
                <div className="row align-items-lg-center" style={{display:'flex', flexDirection:'row', flexWrap:'wrap-reverse', alignItems:'center'}} >

                    <div className="col-md-6 col-lg-5 order-0 order-md-1">
                    <Thumbnail classes="about-thumb" imgSrc={ServiceThumb}/>
                    </div>
                    <div className="col-md-6 col-lg-7 order-1">
                        <Content classes="about-content ml-0">
                           
                            <h2>Red</h2>
                            <p>Somos parte de una red nacional con 55 integrantes.</p>
                        </Content>
                    </div>
                </div>
            </div>
        </div>
            
            <CallToAction/>
            <Footer/>
            <LoginRegister/>
            <MobileMenu/>
        </Fragment>
    );
};

export default Somos;