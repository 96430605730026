import React from 'react';
import {Link} from 'gatsby'
import SlickSlider from '../../components/UI/Slick/index'
//

const NextArrow = ({className, onClick}) => {
    return (
        <button className={className} onClick={onClick}><i className="fa fa-angle-right" style={{color:'rgb(255, 201, 102)'}}/></button>
    )
};

const PrevArrow = ({className, onClick}) => {
    return (
        <button className={className} onClick={onClick}><i className="fa fa-angle-left" style={{color:'rgb(255, 201, 102)'}}/></button>
    )
};


function BlogItem(props) {
    const blogURL = `/blog/${props.title.split(' ').join('-').toLocaleLowerCase()}?id=${props.id}`;

    const settings = {
        infinite: true,
        autoplay: true,
        autoplaySpeed:5000,
        slidesToShow: 1,
        arrows: true,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
        responsive: [
            {
                breakpoint: 500,
                settings: {
                    arrows: false,
                    dots: true
                }
            }
        ]
    };
    console.log(props)
    return (

            <div className="blog-item">
                {
                    props.thumb ? (
                        <figure className="blog-thumb">
                            <Link to={`${process.env.PUBLIC_URL + blogURL}`}>
                                <img src={require('../../assets/img/' + props.thumb)} alt={props.title} />
                            </Link>
                        </figure>
                    ) : null
                }
                <div className="blog-content">
                    <div className={'slider-area'} >
                <SlickSlider settings={settings} >

                        <div key={`01`}>
                            <div className="slider-item" style={{height:'493px',backgroundImage: `url(${require('../../assets/img/slider/bamx-historia-1.png')})`}}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-7">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div key={`01`}>
                            <div className="slider-item" style={{height:'493px',backgroundImage: `url(${require('../../assets/img/slider/bamx-historia-2.png')})`}}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-7">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div key={`01`}>
                            <div className="slider-item" style={{height:'493px',backgroundImage: `url(${require('../../assets/img/slider/bamx-historia-3-cortada.png')})`}}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-7">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div key={`01`}>
                            <div className="slider-item" style={{height:'493px',backgroundImage: `url(${require('../../assets/img/slider/bamx-historia-4.png')})`}}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-7">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    

                



                </SlickSlider>
            </div>

                </div>
            </div>

    );
}

export default BlogItem;