import React, {useState} from 'react'
import { GoogleMap, Marker, withGoogleMap, withScriptjs, InfoWindow } from "react-google-maps"
const { MarkerWithLabel } = require("react-google-maps/lib/components/addons/MarkerWithLabel");
const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");

const Map = withScriptjs(withGoogleMap((props) => {
  const [position, setPosition] = useState(null);

  const handleToggle = (item) => {
    setPosition({position:{
      lat:item.lat,
      lng:item.lng
    }})
  }

  return(
    <GoogleMap
    defaultZoom={4}
    defaultCenter={{ lat: 20.5419313, lng: -101.3153776 }}
  >
    <MarkerClusterer
      onClick={props.onMarkerClustererClick}
      averageCenter
      enableRetinaIcons
      gridSize={60}
    >


      <MarkerWithLabel
      key='01'
 
      position={{ lat: 32.4712244, lng: -116.8736531 }}
      labelAnchor={new google.maps.Point(0, 0)}
      labelStyle={{backgroundColor: "yellow", fontSize: "16px", padding: "10px"}}
      >
        <div>Tijuana</div>
      </MarkerWithLabel>
      <MarkerWithLabel
      position={{ lat: 31.8805392, lng: -116.5974843 }}
      labelAnchor={new google.maps.Point(0, 0)}
      labelStyle={{backgroundColor: "yellow", fontSize: "16px", padding: "10px"}}
      >
        <div>Ensenada</div>
      </MarkerWithLabel>




    </MarkerClusterer>

				 
  </GoogleMap>
  )

}))


export default Map