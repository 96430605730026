import React from 'react';
import SectionTitle from "../UI/SectionTitle";
import BlogItem from "./blogItem";
import BlogItem2 from "./blogItem2";
import BlogItem3 from "./blogItem3";

import Blogs from '../../data/Blog/blog';


function Blog() {
    return (

<>
                    {
                        Blogs.slice(0,1).map(blog =>(
                            <BlogItem key={blog.id} id={blog.id} imgs={blog.imgs} title={blog.title} excerpt={blog.excerpt} postBy={blog.author.name} date={blog.publishDate}/>
                            
                        ))
                    }

</>
    );
}

export default Blog;